import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import styles from './BecomeHeroPage.module.scss'

class BecomeHeroPage extends Component {
  render() {
    const {
      intl,
      onSubmit,
      onNameChange,
      onProjectNameChange,
      onProjectDescriptionChange,
      onContactsChange,
    } = this.props

    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <h1 className={styles.title}>
            <FormattedMessage id="home.becomeHero" />
          </h1>
          <form name="become-a-hero" method="post" data-netlify="true" onSubmit={onSubmit} className={styles.form}>
            <input type="hidden" name="form-name" value="become-a-hero" className={styles.input} />
            <input
              onChange={(event) => onNameChange(event.target.value)}
              required
              type="text"
              name="name"
              placeholder={intl.formatMessage({ id: 'home.yourName' })}
              className={styles.input}
            />
            <input
              onChange={(event) => onProjectNameChange(event.target.value)}
              required
              type="text"
              name="projectName"
              placeholder={intl.formatMessage({ id: 'home.projectName' })}
              className={styles.input}
            />
            <textarea
              onChange={(event) => onProjectDescriptionChange(event.target.value)}
              required
              type="text"
              name="projectDescription"
              placeholder={intl.formatMessage({ id: 'home.projectDescription' })}
              className={styles.textarea}
            />
            <input
              onChange={(event) => onContactsChange(event.target.value)}
              required
              type="text"
              name="contacts"
              placeholder={intl.formatMessage({ id: 'home.contacts' })}
              className={styles.input}
            />
            <button type="submit" className={styles.button}>
              <FormattedMessage id="home.sendRequest" />
            </button>
          </form>
        </div>
      </div>
    )
  }
}

BecomeHeroPage.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  html: PropTypes.string,
  locale: PropTypes.string,
}

export default injectIntl(BecomeHeroPage)
