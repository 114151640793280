import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import Ad from '../Ad'
import Img from 'gatsby-image'
import styles from './AboutPage.module.scss'
import BecomeHeroPage from '../BecomeHeroPage'

class AboutPage extends Component {
  render() {
    const {
      title,
      promoImage,
      bodyNode: {
        childMarkdownRemark: { html },
      },
      // ad,
    } = this.props.data

    // const { locale } = this.props

    return (
      <div className={styles.root}>
        <h1 className={styles.title}>{title}</h1>
        <Img className={styles.promoPicture} fluid={promoImage.fluid} />
        <div className={styles.inner}>
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: html }} />
          {/* <div className={styles.ad}>
            <Ad locale={locale} ad={ad} />
          </div> */}
        </div>
        <div className={styles.becomeHero}>
          <BecomeHeroPage />
        </div>
      </div>
    )
  }
}

AboutPage.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  html: PropTypes.string,
  locale: PropTypes.string,
}

export default AboutPage
