import React from 'react'
import { graphql } from 'gatsby'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import PageHelmet from 'src/components/PageHelmet'
import AboutPage from 'src/components/AboutPage'
import withLayout from 'src/components/withLayout'
import { injectIntl } from 'react-intl'

class About extends React.Component {
  render() {
    const {
      data: { datoCmsAboutUsPage: data, promotion },
      locale,
      changeLocale,
      intl,
    } = this.props

    return (
      <>
        <PageHelmet title={intl.formatMessage({ id: 'home.about' })} locale={locale} defer={false} />
        <Header full={true} locale={locale} />
        <AboutPage data={data} locale={locale} ad={promotion} changeLocale={changeLocale} />
        <Footer locale={locale} />
      </>
    )
  }
}

export const query = graphql`
  query($locale: String!) {
    datoCmsAboutUsPage(locale: { eq: $locale }) {
      title
      promoImage {
        url
        fluid(maxWidth: 1140, imgixParams: { fm: "jpg", auto: "compress, format" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
    promotion: allDatoCmsPromotion(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          autoplayDelay
          slides {
            id
            title
            image {
              url
            }
            link
          }
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'about',
}

export default withLayout(customProps)(injectIntl(About))
